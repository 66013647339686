<template>
  <v-layout row align-center class="upload-header">
    <w-icon color="primary" small class="mr-2">{{ folderIcon }}</w-icon>
    <div v-if="name" class="upload-header__name">{{ name }}</div>
  </v-layout>
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
  name: {
    type: String,
    default: ''
  },
  total: {
    type: Number,
    required: true
  }
})

// Folder icon based on whether folder has items
const folderIcon = computed(() => props.total > 0 ? 'fas fa-folder' : 'far fa-folder')
</script>

<style scoped>
.upload-header {
  width: 100%;
  align-items: center;
}

.upload-header__name {
  font-weight: 500;
  flex: 1;
  margin-right: 8px;
}
</style> 